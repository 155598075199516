<template>
  <v-layout class="d-none d-md-flex">
    <h2 class="logo pos-abs" />
    <div class="bg-post-office-left" />
    <div class="bg-post-office-right" />
  </v-layout>
</template>
<script>
export default {
  name: 'PostOffice'
}
</script>
